
import React, { useState } from 'react';
import '../styles/home.css';
const PetInfo = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [preview, setPreview] = useState('');
  const [species, setSpecies] = useState('');
  const [breed, setBreed] = useState('');



   const handleSpeciesChange = (event) => {
     setSpecies(event.target.value);
    setBreed(''); 
   };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    
    if (selectedFile) {
      
      if (selectedFile.type === 'application/pdf') {
        setFile(selectedFile);
        setError('');
      } else {
        setError('Please upload a valid PDF file.');
        setFile(null);
      }
    }
  };

  const handleUpload = () => {
    if (!file) {
      setError('No file selected.');
      return;
    }

    
    console.log('Uploading file:', file);

    
    setFile(null);
  };
  const dogBreeds = [
        'Labrador Retriever', 'Golden Retriever', 'German Shepherd', 'Beagle', 
        'Bulldog (English, French)', 'Poodle (Standard, Miniature, Toy)', 'Boxer',
        'Dachshund', 'Siberian Husky', 'Yorkshire Terrier', 'Shih Tzu', 
        'Cocker Spaniel', 'Border Collie', 'Australian Shepherd', 'Pug',
        'Rottweiler (restricted in some areas)', 'Chihuahua', 
        'Doberman Pinscher (restricted in some areas)', 'Cavalier King Charles Spaniel',
        'Great Dane', 'Maltese', 'Schnauzer (Miniature, Standard, Giant)', 
        'Basset Hound', 'Bernese Mountain Dog', 'Vizsla', 'Collie', 
        'Weimaraner', 'Newfoundland', 'Boston Terrier', 'Samoyed'
      ];
    
       const catBreeds = [
         'Domestic Shorthair', 'Domestic Longhair', 'Abyssinian', 'American Shorthair',
         'Siamese', 'Persian', 'Maine Coon', 'Ragdoll', 'Bengal', 'Sphynx',
         'British Shorthair', 'Scottish Fold', 'Birman', 'Norwegian Forest Cat',
         'Russian Blue', 'Devon Rex', 'Oriental Shorthair', 'Himalayan', 'Manx',
         'Tonkinese'
       ];
    
  return (
    <div >
    <div className='pdf-uploader-container'>
      <input
        type='file'
        accept='.pdf'
        id='file-input'
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
          <label htmlFor='file-input' className='upload-button'>
        Choose PDF
      </label>
      {file && (
        <div className='file-info'>
          <p>Selected file: {file.name}</p>
          <button className='upload-btn' onClick={handleUpload}>
            Upload
          </button>
        </div>
      )}
      {error && <p className='error'>{error}</p>}
      <div className='Add-png'>Add file(jpg.jpeg.png)</div>
      <p className='Add-png-1'>or drop files to upload</p>
           
    </div> <h2 style={{marginTop:"20px",paddingLeft:"190px"}}>Pet Details</h2>
    <div className='profile-list'>
    
            <div className='prf-1'>
              <p><label>Name of your Pet</label></p>
              <input type='text' style={{paddingLeft:'10px', fontSize:"18px"}} className='input-type' />
              
              <div style={{marginTop:"20px"}}>
                <p><label>Select your Pet's Breed</label></p>
                <select 
                  style={{paddingLeft:'10px', fontSize:"18px"}} 
                  className='input-type' 
                  onChange={(e) => setBreed(e.target.value)}
                  value={breed}
                >
                  <option value="">--Select a breed--</option>
                  {species === 'dog' && dogBreeds.map((dogBreed, index) => (
                    <option key={index} value={dogBreed}>{dogBreed}</option>
                  ))}
                  {species === 'cat' && catBreeds.map((catBreed, index) => (
                    <option key={index} value={catBreed}>{catBreed}</option>
                  ))}
                </select>
              </div>
              <div style={{marginTop:"20px"}}>
                <p><label>Tell us what's your Pet's Age</label></p>
                <input type='text' style={{paddingLeft:'10px', fontSize:"18px"}} className='input-type' />
              </div>
            </div>
            <div className='prf-2'>
              <p><label>Tell us what's your Pet's Weight</label></p>
              <input type='text' style={{paddingLeft:'10px', fontSize:"18px"}} className='input-type' />
              <div style={{marginTop:"20px"}}>
                <p><label>Tell us what's your Pet's Species</label></p>
                <select 
                  style={{paddingLeft:'10px', fontSize:"18px"}} 
                  id="species-select" 
                  className="input-type"
                  onChange={handleSpeciesChange}
                  value={species}
                >
                  <option value="">--Select--</option>
                  <option value="dog">Dog</option>
                  <option value="cat">Cat</option>
                </select>
              </div>          
              
          <button className='prf-sub-btn'>Create</button> 
              
            </div>
            <div className='prf-3'>
            <div style={{marginTop:"20px",paddingTop:"20px"}}>
                <input type='checkbox' />
                <label style={{paddingLeft:"10px"}}>Male</label>
                <input type='checkbox' style={{marginLeft:"20px"}} />
                <label style={{paddingLeft:"10px"}}>Female</label>
              </div>
              <div style={{marginTop:"30px"}}>
                <p><label>Tell us what's your Pet's Height</label></p>
                <input 
                  type='text' 
                  style={{border:'1px solid grey', height:'35px', borderRadius:"5px", marginTop:"5px", paddingLeft:'10px', fontSize:"18px"}} 
                /> ft
              </div>
            
            </div>
          </div>
          {/* <button className='prf-sub-btn'>Create</button> */}
        </div>
      
  );
};
export default PetInfo;


















