import React, { useState } from 'react';
import "../Pet-Appointment/Appoint.css";

import list1 from '../../../my-metavet/src/assets/images/happy-cartoon-dog-sitting.png';
import list3 from '../../../my-metavet/src/assets/images/happy-cartoon-puppy-sitting.png';
import list4 from '../../../my-metavet/src/assets/images/little-cute-cat-cartoon-character.png';
import list5 from '../../../my-metavet/src/assets/images/white-rabbit-animal-cartoon-white-background.png';
import list6 from '../../../my-metavet/src/assets/images/happy-puppy-with-heart-tag-illustration.png';

const PetAppointments = () => {
  const [appointments, setAppointments] = useState([
    {
      id: '1',
      image: list1,
      petName: 'Max',
      type: 'Dog',
      date: '2024-10-05',
      time: '10:00 AM',
      reason: 'Vaccination',
      vet: 'Dr. Smith',
      notes: 'Bring vaccination records',
    },
    {
      id: '2',
      image: list4,
      petName: 'Bella',
      type: 'Cat',
      date: '2024-10-06',
      time: '2:00 PM',
      reason: 'Annual Check-up',
      vet: 'Dr. Johnson',
      notes: 'Need to discuss diet',
    },
    {
      id: '3',
      image: list3,
      petName: 'Charlie',
      type: 'Dog',
      date: '2024-10-10',
      time: '1:00 PM',
      reason: 'Dental Cleaning',
      vet: 'Dr. Jasmine Kimberly',
      notes: 'Monitor allergies',
    },
    {
      id: '4',
      image: list5,
      petName: 'Luna',
      type: 'Rabbit',
      date: '2024-10-12',
      time: '11:30 AM',
      reason: 'Wellness Check',
      vet: 'Dr. Johnson',
      notes: 'Confirm spay Status',
    },
    {
      id: '5',
      image: list6,
      petName: 'Daisy',
      type: 'Dog',
      date: '2024-10-15',
      time: '3:00 PM',
      reason: 'Behaviour Consultation',
      vet: 'Dr. Jasmine Kimberly',
      notes: 'Check for anxiety issues',
    },
    {
      id: '6',
      image: list1,
      petName: 'Max',
      type: 'Dog',
      date: '2024-10-05',
      time: '10:00 AM',
      reason: 'Vaccination',
      vet: 'Dr. Smith',
      notes: 'Bring vaccination records',
    },
    {
      id: '7',
      image: list4,
      petName: 'Bella',
      type: 'Cat',
      date: '2024-10-06',
      time: '2:00 PM',
      reason: 'Annual Check-up',
      vet: 'Dr. Johnson',
      notes: 'Need to discuss diet',
    },
    {
      id: '8',
      image: list3,
      petName: 'Charlie',
      type: 'Dog',
      date: '2024-10-10',
      time: '1:00 PM',
      reason: 'Dental Cleaning',
      vet: 'Dr. Jasmine Kimberly',
      notes: 'Monitor allergies',
    },
    {
      id: '9',
      image: list5,
      petName: 'Luna',
      type: 'Rabbit',
      date: '2024-10-12',
      time: '11:30 AM',
      reason: 'Wellness Check',
      vet: 'Dr. Johnson',
      notes: 'Confirm spay Status',
    },
    {
      id: '10',
      image: list6,
      petName: 'Daisy',
      type: 'Dog',
      date: '2024-10-15',
      time: '3:00 PM',
      reason: 'Behaviour Consultation',
      vet: 'Dr. Jasmine Kimberly',
      notes: 'Check for anxiety issues',
    },
  ]);

  const [editingAppointmentId, setEditingAppointmentId] = useState(null);
  const [editForm, setEditForm] = useState({});

  const handleEdit = (appointment) => {
    setEditingAppointmentId(appointment.id);
    setEditForm(appointment);
  };

  const handleSave = () => {
    setAppointments(appointments.map(appointment =>
      appointment.id === editingAppointmentId ? editForm : appointment
    ));
    setEditingAppointmentId(null);
  };

  const handleDelete = (id) => {
    setAppointments(appointments.filter(appointment => appointment.id !== id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  return (
    <div>
      <h1>Pet Appointments</h1>
      <table className='Appoinment-pet-table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Pet Image</th>
            <th>Pet Name</th>
            <th>Type</th>
            <th>Appointment Date</th>
            <th>Time</th>
            <th>Reason</th>
            <th>Vet/Provider Name</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.id}</td>
              <td>
                <img src={appointment.image} alt={appointment.petName} style={{ height: '100px' }} />
              </td>
              {editingAppointmentId === appointment.id ? (
                <>
                  <td>
                    <input
                      type="text"
                      name="petName"
                      value={editForm.petName}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="type"
                      value={editForm.type}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      name="date"
                      value={editForm.date}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="time"
                      value={editForm.time}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="reason"
                      value={editForm.reason}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="vet"
                      value={editForm.vet}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="notes"
                      value={editForm.notes}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <button className='pet-add-btn1' onClick={handleSave}>Save</button>
                    <button className='pet-add-btn2' onClick={() => setEditingAppointmentId(null)}>Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{appointment.petName}</td>
                  <td>{appointment.type}</td>
                  <td>{appointment.date}</td>
                  <td>{appointment.time}</td>
                  <td>{appointment.reason}</td>
                  <td>{appointment.vet}</td>
                  <td>{appointment.notes}</td>
                  <td>
                    <button className='appoint-btn-pets-1' onClick={() => handleEdit(appointment)}>Edit</button>
                    <button className='appoint-btn-pets-2' onClick={() => handleDelete(appointment.id)}>Delete</button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PetAppointments;
