import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import metavet from '../../../src/assets/images/iconpng (1).png';
import "../styles/navigation.css";

const Index = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop) {
      
      setIsHidden(true);
    } else {
  
      setIsHidden(false);
    }
    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); 
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  return (
    <nav className={`main-nav ${isHidden ? 'hidden' : ''}`}>
      <div className='nav-wrapper'>
        <div className='logo'>
          <li><img src={metavet} alt='' style={{ height: "50px" }} /></li>
          <li style={{ paddingTop: "15px" }}>metavet</li>
        </div>
        <ul className='nav-list'>
          <li>Find a Doctor</li>
          <li>News</li>
          <li>Services</li>
          <li>Contact Us</li>
        </ul>
        <div className='profile-avatar'><FontAwesomeIcon icon={faUser} style={{ paddingRight: "10px" }} />Profile</div>
      </div>
    </nav>
  );
};

export default Index;
