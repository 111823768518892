import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faStar,faClock,faCalendarDays, faVideo, faPhone, faEnvelope,faPaperPlane,faStethoscope,faHeart,faBed,faPersonHalfDress, faCalendarWeek, faComment,faDownload } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';

import doctor1 from '../assets/images/portrait-3d-male-doctor-removebg-preview.png';
import 'react-calendar/dist/Calendar.css'; 
import '../@core/styles/doctorInfo.css';

const Doctor = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  const [dates, setDates] = useState('');
  const [time, setTime] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Appointment scheduled for ${date} at ${time}`);
    
    setDate('');
    setTime('');
  };
  const optionStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer'
  };
  
  const iconStyle = {
    fontSize: '20px',
    marginRight: '10px',
    padding:"10px",
    
  };
  
  const getPaymentClass = (payment) => {
    switch (payment) {
        case 'FullyPaid':
            return 'payment fully-paid';
        case 'UnPaid':
            return 'payment UnPaid';
        case 'PartiallyPaid':
            return 'payment partially-paid';
        default:
            return 'payment';
    }
};

  const reservations = [
    { id: '#R001  NEW', patientName: 'John Doe ', reservationDate: '2024-10-01', totalAmount: 150, payment: 'FullyPaid' },
    { id: '#R002', patientName: 'Jane Smith', reservationDate: '2024-10-02', totalAmount: 200, payment: 'UnPaid' },
    { id: '#R003', patientName: 'Sam Brown', reservationDate: '2024-10-03', totalAmount: 100, payment: 'PartiallyPaid' },
    { id: '#R004', patientName: 'Emily Davis', reservationDate: '2024-10-04', totalAmount: 250, payment: 'FullyPaid' },
    { id: '#R005', patientName: 'Michael Johnson', reservationDate: '2024-10-05', totalAmount: 175, payment: 'UnPaid' },
    { id: '#R006', patientName: 'Sarah Wilson', reservationDate: '2024-10-06', totalAmount: 300, payment: 'PartiallyPaid' },
    { id: '#R007', patientName: 'David Lee', reservationDate: '2024-10-07', totalAmount: 120, payment: 'FullyPaid' },
    { id: '#R008', patientName: 'Linda Taylor', reservationDate: '2024-10-08', totalAmount: 220, payment: 'UnPaid' },
    { id: '#R009', patientName: 'James Anderson', reservationDate: '2024-10-09', totalAmount: 80, payment: 'PartiallyPaid' },
    { id: '#R010', patientName: 'Jessica Martin', reservationDate: '2024-10-10', totalAmount: 90, payment: 'FullyPaid' }
  
  ];
  const downloadCSV = () => {
    const csvRows = [];
    
    csvRows.push(['ID', 'Patient Name', 'Reservation Date', 'Total Amount', 'Payment Status'].join(','));

    
    reservations.forEach(reservation => {
        csvRows.push([
            reservation.id,
            reservation.patientName,
            reservation.reservationDate,
            reservation.totalAmount,
            reservation.payment
        ].join(','));
    });

    
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'reservations.csv');
    a.click();
    window.URL.revokeObjectURL(url); 
};
  return (
    <div>
      <div className='Appoint-page'>
        <div className='Appoint1'>
          <input type='search' placeholder='  Search..' />
        </div>
        <div className='Appoint2'>
          {/* <FontAwesomeIcon icon={faUser} style={{ paddingRight: '10px', fontSize: '22px' }} /> */}
          {/* <span style={{ fontSize: '21px', fontWeight: '500' }}>Dr. Michael Grossett </span> */}
        </div>
      </div>
      <div className='Calendar-container'>
        <div className='calender1'>
         <div className='cal1'> <img src={doctor1} alt='' style={{height:"300px",backgroundColor:"#52B2AD",borderRadius:"20px"}}/>
        </div> 
                <div className='cal2'><h2 style={{paddingLeft:"20px",paddingTop:"10px",color:"black"}}>Dr. Michael Grossett</h2> <h4 style={{paddingLeft:"20px",paddingTop:"10px",color:"#ff8da1",}}>Veterinary Doctor <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> 
                
                </h4>
                <div className='special-doc'><span style={{color:"#2a9df4",backgroundColor:"lightblue",padding:"10px",borderRadius:"10px"}}>Co-founder</span> <span style={{color:"#33C928",backgroundColor:"#E0FFD2",marginLeft:"10px",padding:"10px",borderRadius:"10px"}}>Surgeon</span> <span style={{color:"#FFCC00",backgroundColor:"#FFFFAD",marginLeft:"10px",padding:"10px",borderRadius:"10px"}}> Veterian</span></div>  <span style={{ color: "red", marginRight: "10px" }}>
                 
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"30px"}} />
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}} />
                </span>
                08:00-17:00
              <p style={{paddingTop:"20px",color:"lightgray",paddingLeft:"20px"}}>  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
              <p style={{fontSize:"22px",paddingTop:"20px",paddingLeft:"30px",color:"gray"}}><FontAwesomeIcon style={{backgroundColor:"lightgray",padding:"10px",borderRadius:"50px",}} icon={faEnvelope} /><FontAwesomeIcon style={{backgroundColor:"lightgray",padding:"10px",borderRadius:"50px",marginLeft:"10px"}} icon={faPaperPlane} /></p> </div>  
               </div>
        <div className='calender2'><Calendar
          onChange={handleDateChange}
          value={date}
          className='small-calendar'
        /></div>
        
      </div>
      <div className='facilities'>
        <div className='facilities1'>
          <div className='icon-list'>
          <div className='icon1'>
            <div>
            <FontAwesomeIcon icon={faUser} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",borderRadius:"10px",color:"#fff"}}/></div>
            <div><p style={{color:"gray"}}>Total Patient</p>
            <p style={{paddingLeft:"20px",color:"#52B2AD"}}>1,250</p>
            </div>  </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faPersonHalfDress} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",color:"#fff",borderRadius:"10px"}}/></div>
            <div><p style={{color:"gray"}}>Total Staff</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>250</p>
            </div>
            </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faBed} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",borderRadius:"10px",color:"#fff",paddingTop:"10px"}}/></div>
            <div><p style={{color:"gray"}}>Total Room</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>180</p></div>
          </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faStethoscope} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"5px",borderRadius:"10px",color:"#fff"}}/></div>
            <div><p style={{color:"gray"}}>Total Doctor</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>120</p></div>
        </div>
        <div className='Shedule2'>
      <FontAwesomeIcon icon={faUser} style={{ fontSize: '25px' }} />
      <span style={{ fontSize: '22px', paddingLeft: '10px', fontWeight: '500', color:"gray"}}>Appointment Type</span>
      <div style={{ marginTop: '20px' ,display:"flex"}}>
      
        <div style={optionStyle} className='color-chat-1'>
          <FontAwesomeIcon icon={faVideo} style={iconStyle} />
          {/* <span style={textStyle}>Video Call</span> */}
        </div>
        <div style={optionStyle} className='color-chat-2'>
          <FontAwesomeIcon icon={faPhone} style={iconStyle} />
          {/* <span style={textStyle}>Phone Call</span> */}
        </div>
        <div style={optionStyle} className='color-chat-3' >
        <FontAwesomeIcon  icon={faComment}  style={iconStyle} />
          {/* <span style={textStyle}>WhatsApp Chat</span> */}
        </div>
        
      </div>
    </div>
          </div>        

        </div>



      </div>


<div>
            <h2>Patient Reservation</h2>
            <table>
                <thead>
                    <tr>
                        <th>Reservation ID</th>
                        <th>Patient Name</th>
                        <th>Reservation Date</th>
                        <th>Total Amount</th>
                        <th>Payment</th>
                        <th>Download CSV</th>
                    </tr>
                </thead>
                <tbody > 
                    {reservations.map((reservation) => (
                        <tr  key={reservation.id}>
              
                            <td className='td-body'>{reservation.id}</td>
                            <td className='td-body' style={{color:"blue"}}>{reservation.patientName}</td>
                            <td className='td-body'>{reservation.reservationDate}</td>
                            <td className='td-body'>${reservation.totalAmount}</td>
                            <td className={getPaymentClass(reservation.payment)}>{reservation.payment}</td>
                           <td className='td-body1'> <button className="downloadcsv" onClick={downloadCSV}>  <FontAwesomeIcon icon={faDownload} style={{paddingRight:"10px",fontSize:"25px",}} /></button></td>
      
                        </tr>
                        
                    ))}
                </tbody>
            </table>
        </div>
  
  </div>


    
  );
};

export default Doctor;
