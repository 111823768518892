
import React, { useState } from 'react';
import '../../../@core/styles/Health.css';
import dog from '../../../assets/images/cheerful-dog-sitting-cartoon-character.png';
import dog2 from '../../../assets/images/happy-cartoon-puppy-sitting.png';
import cat from '../../../assets/images/little-cute-cat-cartoon-character.png';
import rabbit from '../../../assets/images/white-rabbit-animal-cartoon-white-background.png';
import dog3 from '../../../assets/images/happy-puppy-with-heart-tag-illustration.png';

const initialPetsData = [
  {
    id: 1,
    name: "Max",
    type: "Dog",
    appointmentDate: "2024-10-05",
    time: "10:00 AM",
    reason: "Vaccination",
    vetProvider: "Dr. Smith",
    notes: "Bring vaccination records",
    healthStatus: "Healthy",
    problems: "None",
    image: dog,
  },
  {
    id: 2,
    name: "Bella",
    type: "Cat",
    appointmentDate: "2024-10-06",
    time: "2:00 PM",
    reason: "Annual Check-up",
    vetProvider: "Dr. Johnson",
    notes: "Need to discuss diet",
    healthStatus: "Overweight",
    problems: "Need to adjust diet",
    image: cat,
  },
  {
    id: 3,
    name: "Charlie",
    type: "Dog",
    appointmentDate: "2024-10-10",
    time: "1:00 PM",
    reason: "Dental Cleaning",
    vetProvider: "Dr. Jasmine Kimberly",
    notes: "Monitor allergies",
    healthStatus: "Healthy",
    problems: "Allergies to pollen",
    image: dog2,
  },
  {
    id: 4,
    name: "Luna",
    type: "Rabbit",
    appointmentDate: "2024-10-12",
    time: "11:30 AM",
    reason: "Wellness Check",
    vetProvider: "Dr. Johnson",
    notes: "Confirm spay status",
    healthStatus: "Healthy",
    problems: "None",
    image: rabbit,
  },
  {
    id:"5",
    name: "Daisy",
    type: "Dog",
    appointmentDate: "2024-10-15",
    time: "3:00 PM",
    reason: "Behaviour Consultation",
    vetProvider: "Dr. Jasmine Kimberly",
    notes: "Check for anxiety issues",
    healthStatus: "Anxious",
    problems: "Separation anxiety",
    image: dog3,
  },
  {
    id: 6,
    name: "Max",
    type: "Dog",
    appointmentDate: "2024-10-05",
    time: "10:00 AM",
    reason: "Vaccination",
    vetProvider: "Dr. Smith",
    notes: "Bring vaccination records",
    healthStatus: "Healthy",
    problems: "None",
    image: dog,
  },
  {
    id: 7,
    name: "Bella",
    type: "Cat",
    appointmentDate: "2024-10-06",
    time: "2:00 PM",
    reason: "Annual Check-up",
    vetProvider: "Dr. Johnson",
    notes: "Need to discuss diet",
    healthStatus: "Overweight",
    problems: "Need to adjust diet",
    image: cat,
  },
  {
    id: 8,
    name: "Charlie",
    type: "Dog",
    appointmentDate: "2024-10-10",
    time: "1:00 PM",
    reason: "Dental Cleaning",
    vetProvider: "Dr. Jasmine Kimberly",
    notes: "Monitor allergies",
    healthStatus: "Healthy",
    problems: "Allergies to pollen",
    image: dog2,
  },
  {
    id: 9,
    name: "Luna",
    type: "Rabbit",
    appointmentDate: "2024-10-12",
    time: "11:30 AM",
    reason: "Wellness Check",
    vetProvider: "Dr. Johnson",
    notes: "Confirm spay status",
    healthStatus: "Healthy",
    problems: "None",
    image: rabbit,
  },
  {
    id:"10",
    name: "Daisy",
    type: "Dog",
    appointmentDate: "2024-10-15",
    time: "3:00 PM",
    reason: "Behaviour Consultation",
    vetProvider: "Dr. Jasmine Kimberly",
    notes: "Check for anxiety issues",
    healthStatus: "Anxious",
    problems: "Separation anxiety",
    image: dog3,
  },
];

const HealthRecord = () => {
  const [petsData, setPetsData] = useState(initialPetsData);
  const [editIndex, setEditIndex] = useState(null);
  const [formData, setFormData] = useState({});

  const handleEditClick = (pet, index) => {
    setEditIndex(index);
    setFormData({ ...pet });
  };

  const handleDeleteClick = (index) => {
    const updatedPetsData = petsData.filter((_, i) => i !== index);
    setPetsData(updatedPetsData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveClick = () => {
    const updatedPetsData = petsData.map((pet, index) =>
      index === editIndex ? { ...pet, ...formData } : pet
    );
    setPetsData(updatedPetsData);
    setEditIndex(null);
  };

  return (
    <div className="pet-health-container">
      <h1>Pet Health Records and Upcoming Appointments</h1>
      <table className="pet-table">
        <thead>
          <tr>
            <th>#</th> {/* New column for ID */}
            <th>Pet Image</th>
            <th>Name</th>
            <th>Type</th>
            <th>Appointment Date</th>
            <th>Time</th>
            <th>Reason</th>
            <th>Vet/Provider Name</th>
            <th>Notes</th>
            <th>Health Status</th>
            <th>Problems</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {petsData.map((pet, index) => (
            <tr key={pet.id}> {/* Use ID as key */}
              <td>{pet.id}</td> {/* Render ID */}
              <td>
                <img src={pet.image} alt={pet.name} className="pet-image" />
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.name
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.type
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="date"
                    name="appointmentDate"
                    value={formData.appointmentDate}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.appointmentDate
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.time
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.reason
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="vetProvider"
                    value={formData.vetProvider}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.vetProvider
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="notes"
                    value={formData.notes}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.notes
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="healthStatus"
                    value={formData.healthStatus}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.healthStatus
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <input
                    type="text"
                    name="problems"
                    value={formData.problems}
                    onChange={handleInputChange}
                  />
                ) : (
                  pet.problems
                )}
              </td>
              <td>
                {editIndex === index ? (
                  <>
                    <button className="save-btn-health" onClick={handleSaveClick}>Save</button>
                    <button className="save-btn-health" onClick={() => setEditIndex(null)}>Cancel</button>
                  </>
                ) : (
                  <>
                  <div style={{display:"flex"}}>
                    <button className="appoint-btn-pets-3" onClick={() => handleEditClick(pet, index)}>Edit</button>
                    <button className="appoint-btn-pets-4" onClick={() => handleDeleteClick(index)}>Delete</button>
                    </div></>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HealthRecord;
