import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faStar,faClock,faCalendarDays, faVideo, faPhone, faEnvelope,faPaperPlane,faStethoscope,faHeart,faBed,faPersonHalfDress, faCalendarWeek, faComment } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import bookdoc from '../../../my-metavet/src/assets/images/medicine-concept-illustration_114360-2802.avif';
import Doctor2 from '../../../my-metavet/src/assets/images/doctors-day-cute-young-handsome-man-lab-coat-glasses-smiling-holding-book.jpg';
import DoctorSmile from '../../../my-metavet/src/assets/images/portrait-3d-male-doctor-removebg-preview.png';
// import doctor2 from '../assets/images/dr3.png';
import 'react-calendar/dist/Calendar.css'; 
import '../@core/styles/doctorInfo.css';

const Doctor = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  const [dates, setDates] = useState('');
  const [time, setTime] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Appointment scheduled for ${date} at ${time}`);
    
    setDate('');
    setTime('');
  };
  const optionStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer'
  };
  
  const iconStyle = {
    fontSize: '20px',
    marginRight: '10px',
    padding:"10px",
    
  };
  
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    bookingDate: '',
});

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
};

const handleSubmits = (e) => {
    e.preventDefault();
    console.log('Booking Details:', formData);
    alert('Booking Successful!');

    
    setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        bookingDate: '',
    });
};
    const patients = [
        {
            id: 1,
            name: 'John Doe',
            number: '123-456-7890',
            email: 'johndoe@example.com',
            date: '2024-09-25',
        },
        {
          id: 1,
          name: 'John Doe',
          number: '123-456-7890',
          email: 'johndoe@example.com',
          date: '2024-09-25',
      },
      {
        id: 1,
        name: 'John Doe',
        number: '123-456-7890',
        email: 'johndoe@example.com',
        date: '2024-09-25',
    },
    {
      id: 1,
      name: 'John Doe',
      number: '123-456-7890',
      email: 'johndoe@example.com',
      date: '2024-09-25',
  },
 
    ];

    const editPatient = (id) => {
        alert(`Edit patient with ID: ${id}`);
    };

    const deletePatient = (id) => {
        alert(`Delete patient with ID: ${id}`);
    };

  return (
    <div>
      <div className='Appoint-page'>
        <div className='Appoint1'>
          <input type='search' placeholder='  Search..' />
        </div>
        <div className='Appoint2'>
          {/* <FontAwesomeIcon icon={faUser} style={{ paddingRight: '10px', fontSize: '22px' }} /> */}
          {/* <span style={{ fontSize: '21px', fontWeight: '500' }}>Dr. Michael Grossett </span> */}
        </div>
      </div>
      <div className='Calendar-container'>
        <div className='calender1'>
         <div className='cal1'> <img src={Doctor2} alt='' style={{height:"300px",backgroundColor:"#D3D3D3",borderRadius:"10px",width:"350px"}}/>
        </div> 
                <div className='cal2'><h2 style={{paddingLeft:"20px",paddingTop:"10px",color:"black"}}>Dr. Diannel Russell</h2> <h4 style={{paddingLeft:"20px",paddingTop:"10px",}}>Neurosurgeon Specialist <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> 
                
                </h4>
                <div className='special-doc'><span style={{color:"#2a9df4",backgroundColor:"lightblue",padding:"10px",borderRadius:"10px"}}>Co-founder</span> <span style={{color:"#33C928",backgroundColor:"#E0FFD2",marginLeft:"10px",padding:"10px",borderRadius:"10px"}}>Surgeon</span> <span style={{color:"#FFCC00",backgroundColor:"#FFFFAD",marginLeft:"10px",padding:"10px",borderRadius:"10px"}}> Veterian</span></div>  <span style={{ color: "red", marginRight: "10px" }}>
                 
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"30px"}} />
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}} />
                </span>
                08:00-17:00
              <p style={{paddingTop:"20px",color:"lightgray",paddingLeft:"20px"}}>  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
                <p style={{fontSize:"22px",paddingTop:"20px",paddingLeft:"30px",color:"gray",}}> <button className='doct-book-btn'>Book Now</button> <FontAwesomeIcon style={{backgroundColor:"lightgray",padding:"10px",borderRadius:"50px",}} icon={faEnvelope} /><FontAwesomeIcon style={{backgroundColor:"lightgray",padding:"10px",borderRadius:"50px",marginLeft:"10px"}} icon={faPaperPlane} /></p> </div>  
               </div>
        <div className='calender2'><Calendar
          onChange={handleDateChange}
          value={date}
          className='small-calendar'
        /></div>
        
      </div>

  <p style={{textAlign:"center",fontSize:"38px",fontWeight:"500",paddingTop:"30px"}}><span style={{color:"#52B2AD",paddingRight:"8px"}}>Book</span>Now</p>
<div className='Doc-book-list'>
  <div className='book-list-1'>
    <img src={bookdoc} alt=''/>
  </div>
  <div className='book-list-2'>
            <h1 style={{paddingTop:"20px"}}>Book Appointment</h1>
            <form onSubmit={handleSubmits}>
                <div>
                    <label>
                        
                        <input style={{border:"3px solid #52B2AD",borderRadius:"5px",width:"400px",height:"40px",paddingLeft:"10px",fontSize:"17px",fontWeight:"500",marginTop:"40px"}}
                            type="text"
                            name="name"
                            placeholder='Your Name'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        
                        <input style={{border:"3px solid #52B2AD",borderRadius:"5px",width:"400px",height:"40px",paddingLeft:"10px",fontSize:"17px",fontWeight:"500",marginTop:"40px"}}
                            type="tel"
                            name="phoneNumber"
                            placeholder='Your Number'
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        
                        <input style={{border:"3px solid #52B2AD",borderRadius:"5px",width:"400px",height:"40px",paddingLeft:"10px",fontSize:"17px",fontWeight:"500",marginTop:"40px"}}
                            type="email"
                            name="email"
                            placeholder='Your Email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label>
                        
                        <input style={{border:"3px solid #52B2AD",borderRadius:"5px",width:"400px",height:"40px",paddingLeft:"10px",fontSize:"17px",fontWeight:"500",marginTop:"40px"}}
                            type="date"
                            name="bookingDate"
                            value={formData.bookingDate}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <button type="submit" className='doc-book-app-btn'>Book Now</button>
            </form>
        </div>
</div>












      <div className='facilities'>
        <div className='facilities1'>
          <div className='icon-list'>
          <div className='icon1'>
            <div>
            <FontAwesomeIcon icon={faUser} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",borderRadius:"10px",color:"#fff"}}/></div>
            <div><p style={{color:"gray"}}>Total Patient</p>
            <p style={{paddingLeft:"20px",color:"#52B2AD"}}>1,250</p>
            </div>  </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faPersonHalfDress} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",color:"#fff",borderRadius:"10px"}}/></div>
            <div><p style={{color:"gray"}}>Total Staff</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>250</p>
            </div>
            </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faBed} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"10px",borderRadius:"10px",color:"#fff",paddingTop:"10px"}}/></div>
            <div><p style={{color:"gray"}}>Total Room</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>180</p></div>
          </div>
          <div className='icon1'>
            <div><FontAwesomeIcon icon={faStethoscope} style={{fontSize:"25px",backgroundColor:"#52B2AD",padding:"5px",borderRadius:"10px",color:"#fff"}}/></div>
            <div><p style={{color:"gray"}}>Total Doctor</p>
            <p style={{paddingLeft:"30px",color:"#52B2AD"}}>120</p></div>
        </div>
        
          </div>        

        </div>


        




        
        <div className='facilities2'>
      {/* <h2>Schedule Doctor Appointment</h2>
      <form onSubmit={handleSubmit}>
        <div style={{paddingTop:"15px"}}>
          <label >
            Select Date:
            <input style={{fontSize:"20px",borderRadius:"5px",paddingLeft:"5px",border:"1px solid #D3D3D3"}} 
              type="date"
              value={dates}
              onChange={(e) => setDates(e.target.value)}
              required
            />
          </label>
        </div>
        <div style={{paddingTop:"15px"}}>
          <label>
            Select Time:
            <input style={{fontSize:"20px",borderRadius:"5px",paddingLeft:"5px",border:"1px solid #D3D3D3"}}
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit">Schedule Appointment</button>
      </form> */}
       <FontAwesomeIcon icon={faUser} style={{ fontSize: '25px' }} />
      <span style={{ fontSize: '22px', paddingLeft: '10px', fontWeight: '500', color:"gray"}}>Appointment Type</span>
      <div style={{ marginTop: '20px' ,display:"flex"}}>
      
        <div style={optionStyle} className='color-chat-1'>
          <FontAwesomeIcon icon={faVideo} style={iconStyle} />
          {/* <span style={textStyle}>Video Call</span> */}
        </div>
        <div style={optionStyle} className='color-chat-2'>
          <FontAwesomeIcon icon={faPhone} style={iconStyle} />
          {/* <span style={textStyle}>Phone Call</span> */}
        </div>
        <div style={optionStyle} className='color-chat-3' >
        <FontAwesomeIcon  icon={faComment}  style={iconStyle} />
          {/* <span style={textStyle}>WhatsApp Chat</span> */}
        </div>
     </div>
    </div>
      </div>

       {/* <p className='shedule-head' style={{paddingLeft:"90px"}}>Booking Appointments</p> */}
      <div className='Shedule'>
        {/* <div className='Shedule1'><FontAwesomeIcon icon={faCalendarDays} style={{fontSize:"25px"}} /><span style={{fontSize:"22px",paddingLeft:"10px",fontWeight:"500", color:"gray"}}>Date</span><h4>Mon-Fri </h4><h4>08:00-17:00</h4> </div> */}
         <div className='Shedule2'>
      {/* <FontAwesomeIcon icon={faUser} style={{ fontSize: '25px' }} />
      <span style={{ fontSize: '22px', paddingLeft: '10px', fontWeight: '500', color:"gray"}}>Appointment Type</span>
      <div style={{ marginTop: '20px' ,display:"flex"}}>
      
        <div style={optionStyle} className='color-chat-1'>
          <FontAwesomeIcon icon={faVideo} style={iconStyle} />

        </div>
        <div style={optionStyle} className='color-chat-2'>
          <FontAwesomeIcon icon={faPhone} style={iconStyle} />
          
        </div>
        <div style={optionStyle} className='color-chat-3' >
        <FontAwesomeIcon  icon={faComment}  style={iconStyle} />

        </div>
        
      </div> */}
    </div>
    
      </div> 
      {/* <div>
            <h2 style={{paddingLeft:"100px"}}>Patient List</h2>
            <table className='tab-patient'>
                <thead>
                    <tr>
                      
                        <th>Name</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map((patient) => (
                        <tr key={patient.id}>
                          
                            <td>{patient.name}</td>
                            <td>{patient.number}</td>
                            <td>{patient.email}</td>
                            <td>{patient.date}</td>
                            <td>
                                <button className='patient-list1' onClick={() => editPatient(patient.id)}>Edit</button>
                                <button className='patient-list2' onClick={() => deletePatient(patient.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div> */}
          <div className='patient-full-body-li'>
            <p style={{paddingLeft:"100px",fontSize:"22px",fontWeight:"500",paddingTop:"10px"}}>Patient List</p>
            <div className='patient-list-book'>
                <div className='patient-book-li-1'><p style={{fontSize:"16px",fontWeight:"500",color:"gray"}}>All Apply Queue</p> <p style={{fontSize:"25px",fontWeight:"500",paddingTop:"5px"}}>1432 Patient</p><p style={{fontSize:"16px",fontWeight:"500",color:"gray",paddingTop:"5px"}}>In this Month</p></div>
                <div className='patient-book-li-1'><p style={{fontSize:"16px",fontWeight:"500",color:"gray"}}>New Patient Regular</p> <p style={{fontSize:"25px",fontWeight:"500",paddingTop:"5px"}}>432 Patient</p><p  style={{fontSize:"16px",fontWeight:"500",color:"gray",paddingTop:"5px"}}>In this Month</p></div>
                <div className='patient-book-li-1'><p style={{fontSize:"16px",fontWeight:"500",color:"gray"}}>New Patient Member</p> <p style={{fontSize:"25px",fontWeight:"500",paddingTop:"5px"}}>500 Patient</p><p  style={{fontSize:"16px",fontWeight:"500",color:"gray",paddingTop:"5px"}}>In this Month</p></div>
                <div className='patient-book-li-1'><p style={{fontSize:"16px",fontWeight:"500",color:"gray"}}>New Patient Assurance</p> <p style={{fontSize:"25px",fontWeight:"500",paddingTop:"5px"}}>500 Patient</p><p  style={{fontSize:"16px",fontWeight:"500",color:"gray",paddingTop:"5px"}}>In this Month</p></div>
            </div>
            <div className='patient-li'>
                <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/>Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#2A9DF4",backgroundColor:"#ADD8E6",padding:"5px",borderRadius:"5px",width:"80px",border:"1px solid #2A9DF4"}}>Regular</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>
               
                <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/>Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#9DCD28",backgroundColor:"#FFFFAD",padding:"5px",borderRadius:"5px",width:"80px",border:"1px solid #9DCD28"}}>Member</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>

                <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/>Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#FFCE16",backgroundColor:"#FFFFAD",padding:"5px",borderRadius:"5px",width:"90px",border:"1px solid #FFCE16"}}>Assurance</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>



           
            </div>
            <div className='patient-li'>



            <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/> Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#9DCD28",backgroundColor:"#FFFFAD",padding:"5px",borderRadius:"5px",width:"80px",border:"1px solid #9DCD28"}}>Member</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>

                <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/>Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#FFCE16",backgroundColor:"#FFFFAD",padding:"5px",borderRadius:"5px",width:"90px",border:"1px solid #FFCE16"}}>Assurance</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>

                <div className='patient-booking'>
              <div > <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"45px"}}>Patient Name </p><p style={{fontSize:"19px",fontWeight:"500"}}>John Doe</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Telephone Number</p><p style={{fontSize:"19px",fontWeight:"500"}}>+6283735634323</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}><img src={DoctorSmile} alt='' style={{width:"50px",height:"50px",borderRadius:"50px"}}/>Doctor Name</p> <p style={{fontSize:"19px",fontWeight:"500"}}>Dr. Diannel Russell</p>
              <button className='patient-btn-list'>Decline</button>
              </div> 

              <div>
                <p style={{marginLeft:"100px",fontSize:"16px",fontWeight:"500",color:"#2A9DF4",backgroundColor:"#ADD8E6",padding:"5px",borderRadius:"5px",width:"80px",border:"1px solid #2A9DF4"}}>Regular</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Date of Resgister</p> <p style={{fontSize:"19px",fontWeight:"500"}}>12 Dec, 2023</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"15px"}}>Email</p><p style={{fontSize:"19px",fontWeight:"500"}}>johndoe@example.com</p>
              <p style={{color:"gray",fontSize:"16px",fontWeight:"500",paddingTop:"50px"}}>Estimation Schedule</p> <p style={{fontSize:"19px",fontWeight:"500"}}>7 Jan,2023-10:30</p>
              <button className='patient-btn-list-1'>Approve</button>
                </div>
                </div>
            
           
            </div>
          </div>
  </div>


    
  );
};

export default Doctor;
