import React, { useState } from 'react';
import '../styles/home.css';
import list1 from '../../../src/assets/images/happy-cartoon-dog-sitting.png';
import list2 from '../../../src/assets/images/cheerful-dog-sitting-cartoon-character.png';
import list3 from '../../../src/assets/images/happy-cartoon-puppy-sitting.png';
import list4 from '../../../src/assets/images/little-cute-cat-cartoon-character.png';
import list5 from '../../../src/assets/images/white-rabbit-animal-cartoon-white-background.png';
import list6 from '../../../src/assets/images/happy-puppy-with-heart-tag-illustration.png';
import { Link } from 'react-router-dom';

const MyPet = () => {
  
  const initialPetsData = [
    { id: 1, name: 'Buddy', age: 2, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list1 },
    { id: 2, name: 'Max', age: 3, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list2 },
    { id: 3, name: 'Charlie', age: 4, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list3 },
    { id: 4, name: 'Bella', age: 2, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list4 },
    { id: 5, name: 'Luna', age: 3, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list5 },
    { id: 6, name: 'Daisy', age: 2, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list6 },
    { id: 7, name: 'Buddy', age: 2, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list1 },
    { id: 8, name: 'Max', age: 3, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list2 },
    { id: 9, name: 'Charlie', age: 4, gender: 'Male', date: '15th Dec 2020', time: '11:00AM', img: list3 },
    { id: 10, name: 'Bella', age: 2, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list4 },
    { id: 11, name: 'Luna', age: 3, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list5 },
    { id: 12, name: 'Daisy', age: 2, gender: 'Female', date: '15th Dec 2020', time: '11:00AM', img: list6 },
  
  ];

  
  const [pets, setPets] = useState(initialPetsData);
  const [editPetId, setEditPetId] = useState(null);
  const [editedPet, setEditedPet] = useState({ name: '', age: '', gender: '' });

  
  const handleEditClick = (pet) => {
    setEditPetId(pet.id);
    setEditedPet({ name: pet.name, age: pet.age, gender: pet.gender });
  };


  const handleDeleteClick = (id) => {
    setPets(pets.filter(pet => pet.id !== id));
  };

  
  const handleSaveClick = (id) => {
    setPets(pets.map(pet => (pet.id === id ? { ...pet, ...editedPet } : pet)));
    setEditPetId(null);
  };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPet({ ...editedPet, [name]: value });
  };

  return (
    <div>
      
      <Link to="/petinfo">
        <button className='bttnAdd'>Add Pet</button>
      </Link>
      <h1 style={{ marginBottom: "40px" }}>My Pets</h1>
      
      <table className="petTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Pet</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Date</th>
            <th>Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pets.map((pet) => (
            <tr key={pet.id}>
              <td>{pet.id}</td>
              <td><img src={pet.img} alt={pet.name} style={{ height: "100px", }} /></td>
              {editPetId === pet.id ? (
                <>
                  <td>
                    <input
                    className='input-style'
                      type="text"
                      name="name"
                      value={editedPet.name}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                    className='input-style'
                      type="number"
                      name="age"
                      value={editedPet.age}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                    className='input-style'
                      type="text"
                      name="gender"
                      value={editedPet.gender}
                      onChange={handleChange}
                    />
                  </td>
                  <td>{pet.date}</td>
                  <td>{pet.time}</td>
                  <td>
                    <button className='save-btn-1' onClick={() => handleSaveClick(pet.id)}>Save</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{pet.name}</td>
                  <td>{pet.age}</td>
                  <td>{pet.gender}</td>
                  <td>{pet.date}</td>
                  <td>{pet.time}</td>
                  <td style={{ textAlign: "center" }}>
                    <button className='edit-btn-1' onClick={() => handleEditClick(pet)}>Edit</button>
                    <button className='delete-btn-1' onClick={() => handleDeleteClick(pet.id)}>Delete</button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default MyPet;







