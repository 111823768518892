import "./App.css";
import Component from '../src/components/LoginPage'; 
import Login from "./view/pages/authentication/Login";
import CreateAccount from "./view/pages/authentication/createAccount";
import ForgetPassword from "./view/pages/authentication/forget.Password";
import {BrowserRouter,Routes,Route, } from 'react-router-dom'
import { useEffect } from "react";
import Layout from "./@core/layout/layout";
import SendEmail from "./view/pages/authentication/sendEmail";
import NewPassword from "./view/pages/authentication/NewPassword";
import Home from "./view/pages/authentication/Home";
import MyPet from "./@core/layout/MyPet";
import PetInfo from "./@core/layout/petInfo";
import Doctor from "../src/doctor.Info/doctor";
import Doctor1 from "../src/doctor.Info/doctor1";
import PetAppointments from "./Pet-Appointment/Appointment";
import Doctor2 from "../src/doctor.Info/doctor2";
import HealthRecord from "./view/pages/authentication/HealthRecord";
function App() {




  return (
    <BrowserRouter>
      <Routes>
<Route path="/" element={<Login/>}/>
<Route path="/singup" element={<CreateAccount/>}/>
<Route path="/forget" element={<ForgetPassword/>}/>
<Route path="/newpassword" element={<NewPassword/>}/>
<Route path="/email" element={<SendEmail/>}/>

<Route path="*" element={<h1>404 page not found</h1>}/>
<Route path="/" element={<Layout/>}>
<Route path="/dashboard" element={<Home/>}/>
<Route path="/mypet" element={<MyPet/>}/>
<Route path="/petinfo" element={<PetInfo/>}/>
<Route path="/doctor" element={<Doctor/>}/>
<Route path="/doctor1" element={<Doctor1/>}/>
<Route path="/contact" element={<div>Hi Contact</div>}/>
<Route path="/appoint" element={<PetAppointments/>}/>
<Route path="/doctor2" element={<Doctor2/>}/>
<Route path="/health" element={<HealthRecord/>}/>
</Route>
      </Routes>
      
    </BrowserRouter>
  );
}

export default App;


// <Login/>








































































































































