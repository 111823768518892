
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faClock, faCalendarDays, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import Veterinary1 from '../../../my-metavet/src/assets/images/beautiful-young-female-doctor-looking-camera-office.png';
import bookdoc from '../../../my-metavet/src/assets/images/medicine-concept-illustration_114360-2802.avif';
import 'react-calendar/dist/Calendar.css'; 
import '../@core/styles/doctorInfo.css';
import rb845 from '../../../my-metavet/src/assets/images/rb_845.png'; 

const Doctor = () => {
    const [date, setDate] = useState(new Date());
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        bookingDate: '',
    });
    

    const [patients, setPatients] = useState([
      
        { id: 1, name: 'John Doe', number: '123-456-7890', email: 'johndoe@example.com', date: '2024-09-25', image: rb845 },
        { id: 2, name: 'Jane Smith', number: '987-654-3210', email: 'janesmith@example.com', date: '2024-09-26',  image: rb845 },
        { id: 3, name: 'Michael Brown', number: '456-789-0123', email: 'michaelbrown@example.com', date: '2024-09-27', image: rb845 },
         { id: 4, name: 'Emily Davis', number: '321-654-9870', email: 'emilydavis@example.com', date: '2024-09-28' , image: rb845},
         { id: 5, name: 'Daniel Johnson', number: '654-321-0987', email: 'danieljohnson@example.com', date: '2024-09-29', image: rb845 },
         { id: 6, name: 'Olivia Wilson', number: '789-012-3456', email: 'oliviawilson@example.com', date: '2024-09-30', image: rb845 },
         { id: 7, name: 'Sophia Martinez', number: '234-567-8901', email: 'sophiamartinez@example.com', date: '2024-10-01' , image: rb845},
         { id: 8, name: 'Liam Anderson', number: '345-678-9012', email: 'liamanderson@example.com', date: '2024-10-02', image: rb845 },
         { id: 9, name: 'Ava Thomas', number: '456-789-0123', email: 'avathomas@example.com', date: '2024-10-03', image: rb845 },
         { id: 10, name: 'Noah Jackson', number: '567-890-1234', email: 'noahjackson@example.com', date: '2024-10-04', image: rb845 },
        
    ]);
    
    const [editingPatientId, setEditingPatientId] = useState(null);

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Booking Successful!');
        setFormData({ name: '', phoneNumber: '', email: '', bookingDate: '' });
    };

    const handleEditClick = (patient) => {
        setEditingPatientId(patient.id);
        setFormData({ name: patient.name, phoneNumber: patient.number, email: patient.email, bookingDate: patient.date });
    };

    const handleSave = () => {
        setPatients(patients.map(patient => 
            patient.id === editingPatientId ? { ...patient, ...formData } : patient
        ));
        setEditingPatientId(null);
        setFormData({ name: '', phoneNumber: '', email: '', bookingDate: '' });
    };

    const handleCancel = () => {
        setEditingPatientId(null);
        setFormData({ name: '', phoneNumber: '', email: '', bookingDate: '' });
    };

    const handleDelete = (id) => {
        setPatients(patients.filter(patient => patient.id !== id));
    };

    return (
        <div>
            <div className='Appoint-page'>
                <div className='Appoint1'>
                    <input type='search' placeholder='  Search..' />
                </div>
            </div>

            <div className='Calendar-container'>
                <div className='calender1'>
                    <div className='cal1'>
                        <img src={Veterinary1} alt='' style={{ height: "300px", backgroundColor: "#D3D3D3", borderRadius: "10px", width: "250px" }} />
                    </div>
                    <div className='cal2'>
                        <h2 style={{ paddingLeft: "20px", paddingTop: "10px", color: "black" }}>Dr. Jasmine Kimberly</h2>
                        <h4 style={{ paddingLeft: "20px", paddingTop: "10px", }}>Veterinary Doctor 
                            <span style={{ color: 'orange', paddingLeft: "10px" }}>
                                <FontAwesomeIcon icon={faStar} />
                            </span>
                            <span style={{ color: "black" }}>4.8 (250)</span>
                        </h4>
                        <div className='special-doc'>
                            <span style={{ color: "#2a9df4", backgroundColor: "lightblue", padding: "10px", borderRadius: "10px" }}>Co-founder</span>
                            <span style={{ color: "#33C928", backgroundColor: "#E0FFD2", marginLeft: "10px", padding: "10px", borderRadius: "10px" }}>Surgeon</span>
                            <span style={{ color: "#FFCC00", backgroundColor: "#FFFFAD", marginLeft: "10px", padding: "10px", borderRadius: "10px" }}>Veterinarian</span>
                        </div>
                        <span style={{ color: "red", marginRight: "10px" }}>
                            <FontAwesomeIcon icon={faCalendarDays} style={{ paddingLeft: "20px", paddingTop: "30px" }} />
                        </span>
                        Mon-Fri
                        <span style={{ color: "red", marginLeft: "20px" }}>
                            <FontAwesomeIcon icon={faClock} style={{ paddingRight: "10px" }} />
                        </span>
                        08:00-17:00
                        <p style={{ paddingTop: "20px", color: "lightgray", paddingLeft: "20px" }}>Lorem ipsum dolor sit amet...</p>
                        <p style={{ fontSize: "22px", paddingTop: "20px", paddingLeft: "30px", color: "gray" }}>
                            <FontAwesomeIcon style={{ backgroundColor: "lightgray", padding: "10px", borderRadius: "50px" }} icon={faEnvelope} />
                            <FontAwesomeIcon style={{ backgroundColor: "lightgray", padding: "10px", borderRadius: "50px", marginLeft: "10px" }} icon={faPaperPlane} />
                        </p>
                    </div>
                </div>
                <div className='calender2'>
                    <Calendar onChange={handleDateChange} value={date} className='small-calendar' />
                </div>
            </div>

            <p style={{ textAlign: "center", fontSize: "38px", fontWeight: "500", paddingTop: "30px" }}>
                <span style={{ color: "#52B2AD", paddingRight: "8px" }}>Book</span>Now
            </p>
            <div className='Doc-book-list'>
                <div className='book-list-1'>
                    <img src={bookdoc} alt='' />
                </div>
                <div className='book-list-2'>
                    <h1 style={{ paddingTop: "20px" }}>Book Appointment</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                style={{ border: "3px solid #52B2AD", borderRadius: "5px", width: "400px", height: "40px", paddingLeft: "10px", fontSize: "17px", fontWeight: "500", marginTop: "40px" }}
                                type="text"
                                name="name"
                                placeholder='Your Name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <input
                                style={{ border: "3px solid #52B2AD", borderRadius: "5px", width: "400px", height: "40px", paddingLeft: "10px", fontSize: "17px", fontWeight: "500", marginTop: "40px" }}
                                type="tel"
                                name="phoneNumber"
                                placeholder='Your Number'
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <input
                                style={{ border: "3px solid #52B2AD", borderRadius: "5px", width: "400px", height: "40px", paddingLeft: "10px", fontSize: "17px", fontWeight: "500", marginTop: "40px" }}
                                type="email"
                                name="email"
                                placeholder='Your Email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <input
                                style={{ border: "3px solid #52B2AD", borderRadius: "5px", width: "400px", height: "40px", paddingLeft: "10px", fontSize: "17px", fontWeight: "500", marginTop: "40px" }}
                                type="date"
                                name="bookingDate"
                                value={formData.bookingDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className='doc-book-app-btn'>Book Now</button>
                    </form>
                </div>
            </div>

            <div>
                <h2>Patient List</h2>
                <table className='tab-patient'>
                    <thead>
                        <tr>
                            <th>Patients</th> 
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patients.map((patient) => (
                            <tr key={patient.id}>
                                <td>
                                    <img src={patient.image} alt={patient.name} style={{ width: '100px', height: '100px', borderRadius: '50%' }} /> 
                                </td>
                                <td>
                                    {editingPatientId === patient.id ? (
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        patient.name
                                    )}
                                </td>
                                <td>
                                    {editingPatientId === patient.id ? (
                                        <input style={{ height: "40px", borderRadius: "5px", border: "1px solid lightgrey", padding: "10px", fontSize: "16px", width: "150px" }}
                                            type="tel"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        patient.number
                                    )}
                                </td>
                                <td>
                                    {editingPatientId === patient.id ? (
                                        <input style={{ height: "40px", borderRadius: "5px", border: "1px solid lightgrey", width: "220px", padding: "10px", fontSize: "17px" }}
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        patient.email
                                    )}
                                </td>
                                <td>
                                    {editingPatientId === patient.id ? (
                                        <input
                                            type="date"
                                            name="bookingDate"
                                            value={formData.bookingDate}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        patient.date
                                    )}
                                </td>
                                <td>
                                    {editingPatientId === patient.id ? (
                                        <>
                                            <button className='patientsavebtn1' onClick={handleSave}>Save</button>
                                            <button className='patientsavebtn2' onClick={handleCancel}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button className='patientbtn1' onClick={() => handleEditClick(patient)}>Edit</button>
                                            <button className='patientbtn2' onClick={() => handleDelete(patient.id)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Doctor;
