import { Link } from 'react-router-dom';
import '../../../@core/styles/login.css'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
  };
  

  return (
    <div className='login-wrapper'>
      <div className='login-left'>
        <div className='circal' />
        <div className='glass' />
      </div>
      <div className='login-right'>
        <div className='login-form-container'>
        {/* <button 
            onClick={() => navigate(-1)} 
            className='back-button'
          > <span><FontAwesomeIcon icon={faChevronLeft} /></span>
          </button> */}

          <div className="login-id">
            
      <div className="login-1" style={{marginTop:"30px",fontSize:'29px'}}>Forget Password</div>
     <div className="login-2" style={{fontSize:'18px'}}>Enter the email associated with your account and well send and email to reset your password</div>
          </div>
          <form onSubmit={handleSubmit}>
       <div className="email-id">
        
          <div className="email-1" style={{fontSize:'18px'}}>
            <label>Email Address<span style={{ color: 'red' }}>*</span></label>
            
            <input 
              type="email"  
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
        
       </div>
       
       
   
            <div>
             <Link to="/newpassword">   
              <button  type="submit" className='btn2'>Send Mail</button></Link>
            </div>
            


           <div className='footer2'>
             <div className='footers-1'>
               Back To
             </div>
             <Link to="/" style={{textDecoration:"none"}}>    <div     className='footers-2'>Sign In</div></Link>
           </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;


