import '../../../@core/styles/login.css'
import React, { useState } from 'react';
import logo from '../../../assets/images/download.png';
import profile from "../authentication/images.png.png";
import { useNavigate } from 'react-router-dom'; 

import { Link } from 'react-router-dom';

const NewPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
  };
  
    const [isChecked, setIsChecked] = useState(false);
  
    
     const handleChange = (event) => {
       setIsChecked(event.target.checked);
     };
                   

  return (
    <div className='login-wrapper'>
      <div className='login-left'>
        <div className='circal' />
        <div className='glass' />
      </div>
      <div className='login-right'>
        <div className='login-form-container'>
        {/* <button 
            onClick={() => navigate(-1)} 
            className='back-button'
          > <span><FontAwesomeIcon icon={faChevronLeft} /></span>
          </button> */}

          <div className="login-id" style={{marginTop:"20px"}}>
            
      <div className="login-1" style={{fontSize:"28px"}}>Enter New Password</div>
     <div className="login-2">Your new password must be different from previously used password.</div>
          </div>
          <form onSubmit={handleSubmit}>
       <div className="email-id">
       <div className="email-1">
            <label>Password<span style={{ color: 'red' }}>*</span></label>
            
       <input 
              type="email"  
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
        
          <div className="email-1">
            <label>Confirm Password<span style={{ color: 'red' }}>*</span></label>
            
            <input 
              type="email"  
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
         
       </div>
       
                
            <div>
              <button type="submit" className='btn1'>Continue</button>
            </div>
           <div className='footer1'>
             <div className='foots-1' style={{paddingLeft:"70px"}}>
               Back To
             </div>
             <Link to="/" style={{textDecoration:"none"}}>   <div     className='foots-2'>Sign In</div></Link>
           </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;