import { Link } from 'react-router-dom';
import '../../../@core/styles/login.css'
import React, { useState } from 'react';
import logo from '../../../assets/images/download.png';
import profile from "../authentication/images.png.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <div className='login-wrapper'>
      <div className='login-left'>
        <div className='circal' />
        <div className='glass' />
      </div>
      <div className='login-right'>
        <div className='login-form-container'>
          <div className="login-id">
            <div className="login-1" style={{fontSize:"28px"}}>Sign In To Your Account</div>
            <div className="login-2" style={{fontSize:"18px"}}>Welcome Back You've Been Missed!</div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="email-id">
              <div className="email-1">
                <label>Email Address<span style={{ color: 'red' }}>*</span></label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="email-2">
                <label>Password<span style={{ color: 'red' }}>*</span></label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Link to="forget"  style={{ textDecoration: 'none' }}>
                <span className="forgot-password">Forget Password?</span></Link>
              </div>
            </div>
            <div>
              <Link to="/email">
              <button type="submit">Sign In</button></Link>
            </div>
            <div className='sign'>Or Sign In With</div>
            <div className='images'>
              <div className='img-1'>
                <img src={logo} alt='' style={{ height: '30px' }} />
              </div>
              <div className='img-2'>Sign in with Google</div>
            </div>
            <div className='images'>
              <div className='img-3'>
                <img src={profile} alt='' style={{ height: '25px' }} />
              </div>
              <div className='img-4'>Sign in with Apple</div>
            </div>
             
            <div className='footer' style={{marginTop:"40px"}}>
              <div className='foot-1'>
                Not a Member?
              </div>
              <Link to="/singup" className='foot-2' style={{ textDecoration: 'none' }}>Create an account</Link> 
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;






















































































































































































































































