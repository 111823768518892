import '../../../@core/styles/login.css';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 

const SendEmail = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const navigate = useNavigate(); 


  const hanldeLogin=()=>{
    const fkDb= {
      id: 1,
      username: 'johndoe',
      password: 'password123', 
      email: 'johndoe@example.com',
      firstName: 'John',
      lastName: 'Doe',
      role: 'user', 
      isActive: true,
    }

    localStorage.setItem("userData",fkDb)

    navigate("/dashboard")
  }
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 1 && !isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('OTP:', otp.join(''));
  };

  return (
    <div className='login-wrapper'>
      <div className='login-left'>
        <div className='circal' />
        <div className='glass' />
      </div>
      <div className='login-right'>
        <div className='login-form-container'>
          {/* <button 
            onClick={() => navigate(-1)} 
            className='back-button'
          > <span><FontAwesomeIcon icon={faChevronLeft} /></span>
          </button> */}

          <div className="login-id">
            <div className="logins1">Enter Code</div>
            <div className="logins2">
             <p> An Authentication Code Has Been Sent To </p> 
              <p style={{borderBottom: "1px solid black", display: 'inline'}}>testing@gmail.com</p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
              {otp.map((value, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="text"
                  value={value}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleOtpKeyDown(e, index)}
                  maxLength="1"
                  style={{ width: '50px', textAlign: 'center', fontSize: '18px', height: '50px', borderRadius: '5px' }}
                />
              ))}
            </div>
            <p className='send'>If you don't receive the code! <span className='resend'>Resend</span></p>
         
            <button type="submit" className='btn3' onClick={hanldeLogin}>Verify and Proceed</button>
          </form>

          <div className='footr1'>
            <div className='foot1'>Back To</div>
        
            <Link to="/" style={{textDecoration:"none"}}>     <div className='foot2'>Sign In</div></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
