
import '../../../@core/styles/login.css';
import { Link } from 'react-router-dom';
import '../../../@core/styles/home.css';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import metavet from '../../../assets/images/iconpng (1).png';
import { faCalendarDays, faEnvelope,faCommentDots,faPhoneVolume, faClock, faBell,faPhone,faChevronRight, faStar,faStarHalfStroke, faUsers,faUser,faArrowRight, faLocation, } from '@fortawesome/free-solid-svg-icons';
import pet from '../../../assets/images/cute-dog-spending-time-indoors-removebg-preview.png';
import doctor from '../../../assets/images/portrait-3d-female-doctor.png';
import doctor1 from '../../../assets/images/portrait-3d-male-doctor-removebg-preview.png';
import doctor2 from '../../../assets/images/dr3.png';
import DrCard from '../../components/card/DRCard';
import happyDog from '../../../assets/images/happy-man-with-group-dogs-front-him-3d-render-removebg-preview.png';
import pet1 from '../../../assets/images/cute-dog-spending-time-indoors-removebg-preview.png';
import pet2 from '../../../assets/images/Screenshot_2024-05-16_at_5.06.22_PM-removebg-preview.png';
import pet3 from '../../../assets/images/happy-man-with-group-dogs-front-him-3d-render-removebg-preview.png';
import docpet from '../../../assets/images/4198540.jpg';
import Veterinary1 from '../../../assets/images/beautiful-young-female-doctor-looking-camera-office.png';
import Veterinary2 from '../../../assets/images/doctor-with-his-arms-crossed-white-background.png';
import client from '../../../assets/images/images (16).jpeg';
import client1 from '../../../assets/images/smiling-young-pretty-woman-keeping-hand-waist-pointing-side-isolated-olive-green-background.jpg';
import YoungDoc from '../../../assets/images/portrait-3d-male-doctor-removebg-preview.png';
import { faFacebook, faInstagram, faTwitter,faLinkedin,faPinterest } from '@fortawesome/free-brands-svg-icons';

const doctors = [
    {
      id: 1,
      name: 'Dr. Jasmine Kimberly',
      specialty: 'Veterinary Doctor',
      schedule: 'Mon-Thur 08:00-17:00',
      image: doctor,
    },
    {
      id: 2,
      name: 'Dr. Michael Grossett',
      specialty: 'Veterinary Neurosurgeon',
      schedule: 'Mon-Fri 08:00-17:00',
      image: doctor1,
    },
    {
        id: 3,
        name: 'Dr. Jasmine Kimberly',
        specialty: 'Veterinary Doctor',
        schedule: 'Mon-Thur 08:00-17:00',
        image: doctor2,
      },
      
      {
        id: 3,
        name: 'Dr. Jasmine Kimberly',
        specialty: 'Veterinary Neurosurgeon',
        schedule: 'Mon-Fri 08:00-17:00',
        image: doctor2,
      },
      {
        id: 1,
        name: 'Dr. Jasmine Kimberly',
        specialty: 'Veterinary Doctor',
        schedule: 'Mon-Thur 08:00-17:00',
        image: doctor,
      },
      {
        id: 2,
        name: 'Dr. Michael Grossett',
        specialty: 'Veterinary Neurosurgeon',
        schedule: 'Mon-Fri 08:00-17:00',
        image: doctor1,
      },{
        id: 1,
        name: 'Dr. Jasmine Kimberly',
        specialty: 'Veterinary Doctor',
        schedule: 'Mon-Thur 08:00-17:00',
        image: doctor,
      },
      {
        id: 2,
        name: 'Dr. Michael Grossett',
        specialty: 'Veterinary Neurosurgeon',
        schedule: 'Mon-Fri 08:00-17:00',
        image: doctor1,
      },
      {
        id: 3,
        name: 'Dr. Jasmine Kimberly',
        specialty: 'Veterinary Neurosurgeon',
        schedule: 'Mon-Fri 08:00-17:00',
        image: doctor2,
      },
  ];
const Home = () => {
  const scrollLeft = () => {
    const list = document.querySelector('.doctor-name-list');
    list.scrollBy({
      top: 0,
      left: -800,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    const list = document.querySelector('.doctor-name-list');
    list.scrollBy({
      top: 0,
      left: 800,
      behavior: 'smooth',
    });
  };
  const scrollLefts = () => {
    const container = document.getElementById('review-container');
    container.scrollBy({ left: -800, behavior: 'smooth' });
  };

  const scrollRights = () => {
    const container = document.getElementById('review-container');
    container.scrollBy({ left: 800, behavior: 'smooth' });
  };
  const scrollRef = useRef(null);

   const scroll = (direction) => {
     if (scrollRef.current) {
       const scrollAmount = 500; 
       scrollRef.current.scrollBy({
         left: direction === 'right' ? scrollAmount : -scrollAmount,
         behavior: 'smooth',
       });
     }
    
   };

  
  const moreListRef = useRef(null);

  const scrollMoreList = (direction) => {
    if (moreListRef.current) {
      const scrollAmount = 300; 
      moreListRef.current.scrollBy({
        left: direction === 'right' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      });
    }
  };
  //  const scrollRef = useRef(null);

 const handleScrollLeft = () => {
     if (scrollRef.current) {
         scrollRef.current.scrollBy({ left: -350, behavior: 'smooth' });
     }
 };

 const handleScrollRight = () => {
     if (scrollRef.current) {
         scrollRef.current.scrollBy({ left: 350, behavior: 'smooth' });
     }
 };
 
  return (
    <div className='home-container'>
      

      <div className='content'>
        
     
         
        <main className='' style={{width:"100%"}}>
          
          <div className='head'>
            <div className='head-1'>
              {/* <span>Protect your pet health <p> and take care of</p> your pet health </span> */}
              {/* <h6>Better high-touch, digital model for maternity and improve digital engagement. fertility care is designed to complement your Services.</h6> */}
              <p style={{fontSize:'45px',fontWeight:'700',color:"#52B2AD"}}>Protect your pet and <p>  take care of your</p>  pet health </p>
              <p style={{fontSize:"18px",fontWeight:"500",paddingTop:"20px"}}>Better high-touch, digital model for maternity and improve <p>digital engagement. fertility care is designed to complement </p> your Services.</p>
              <button className='button1'>Book Appointment</button>
              <button className='button2'>Download App</button>
            </div>
            <div className='head-2'>
              {/* <img src={pet} alt='Pet' style={{ height: "250px", }} /> */}
              
               <img className='img-pet1' src={pet3} alt='' style={{ height: "380px", marginTop:"50px"}}/>
              {/* <img className='img-pet1' src={pet2} alt='' style={{ height: "380px", marginTop:"50px"}}/> */}
              <img className='img-pet2' src={pet1} alt='' style={{ height: "450px", }}/>
            </div>
          </div>
          <div className='doctor-time'>
            <div className='doctor-time-1'>
            {/* <div style={{display:"flex",}} >
              <div><img src={appoint1} alt='' style={{height:"200px"}} /></div>
              <div style={{fontSize:"18px",fontWeight:"500",textAlign:"center"}}>Easy online <p>booking here</p></div>
              </div>  
           
            <div style={{display:"flex"}}>
              <div style={{fontSize:"18px",fontWeight:"500",textAlign:"center"}}>Served Directly By<p> Experts</p></div>
              <div><img src={appoint2} alt='' style={{height:"200px"}} /></div>
            </div> 
               */}
             <img src={docpet} alt='' style={{height:"400px",}}/>
            </div>
            <div className='doctor-time-2'>
            <p style={{fontSize:'40px',fontWeight:'700',color:"#52B2AD"}}>Consulation with your<p>best doctor Anywhere</p><p>and Anytime</p> </p>
            <p style={{fontSize:"19px",color:"gray"}}>Now you can make an Appointment with your doctor<p>Anywhere and Anytime via online booking easier.</p></p>
            <div style={{display:"flex",gap:"30px"}}>
              <div><p style={{fontSize:"40px",fontWeight:"500",color:"#52B2AD"}}>98%</p>
              <p style={{fontSize:"18px",fontWeight:"500"}}>Satisfaction rate</p></div>
             <div><p style={{fontSize:"40px",fontWeight:"500",color:"#52B2AD"}}>25M</p><p style={{fontSize:"18px",fontWeight:"500"}}>Registered users</p></div>
            </div>
            <button className='doc-time-btn'>Let's Get Started</button>
            </div>
          </div>
          
           
            <div className='Quick-solution'>
              <div className='Quick-1'>
                <p style={{color:'#FFB1A3',fontSize:"20px",fontWeight:'500'}}>How it Works</p>
                <p style={{fontSize:"35px",fontWeight:"500",marginTop:"20px"}}>Quick Solution For Scheduling <p>With Doctor</p>  </p>
              </div>
              <div className='quick-doctor'>
                {/* <div className='quick-doct1'>< FontAwesomeIcon icon={faUsers} style={{color:"#FFB1A3",fontSize:"40px",}} />
                  <p style={{marginTop:"20px",marginBottom:"10px",fontSize:"20px",color:"black"}}>Find Doctor</p>
                  <p>We're Here To Help whenever<p>You Feel But Keeping You</p>Healthy Is Our Better Priority</p>
                </div> */}
                <div className='quick-doct1'>
   <div className='icon-container icon-bg1'>
      <FontAwesomeIcon icon={faUsers} style={{ color: "#FFB1A3", fontSize: "40px" }} />
       <div className='icon-overlay icon-bg1'>1</div>
    </div>
     
    <p style={{ marginTop: "20px", marginBottom: "10px", fontSize: "20px", color: "black" }}>Find Doctor</p>
    <p>We're Here To Help whenever
      <p>You Feel But Keeping You</p> Healthy Is Our Better Priority
    </p>
  </div>
  <FontAwesomeIcon icon={faArrowRight} style={{color:"#3CB371",fontSize:"30px"}}/>    
                <div className='quick-doct2'>
                <div className='icon-container icon-bg2'>
                <FontAwesomeIcon icon={faUser}  style={{color:"#FFA500",fontSize:"40px",}} />
                <div className='icon-overlay icon-bg2'>2</div>
              </div>
                <p style={{marginTop:"20px",marginBottom:"10px",fontSize:"20px",color:"black"}}>Check Doctor Profile</p>
                <p>We're Here To Help whenever<p>You Feel But Keeping You</p>Healthy Is Our Better Priority</p>
                
                </div>
                <FontAwesomeIcon icon={faArrowRight} style={{color:"#3CB371",fontSize:"30px"}}/>         
                <div className='quick-doct3'>
                <div className='icon-container icon-bg3'>
                <FontAwesomeIcon icon={faCalendarDays}  style={{color:"#3CB371",fontSize:"40px",}}/>
                <div className='icon-overlay icon-bg3'>3</div>
               </div>
                <p style={{marginTop:"20px",marginBottom:"10px",fontSize:"20px",color:"black"}}>Schedule Appointment</p>
                <p>We're Here To Help whenever<p>You Feel But Keeping You</p>Healthy Is Our Better Priority</p>
                
                </div>
                <FontAwesomeIcon icon={faArrowRight} style={{color:"#3CB371",fontSize:"30px"}} />   
                <div className='quick-doct4'>
                <div className='icon-container icon-bg4'>
                <FontAwesomeIcon icon={faStar}  style={{color:"#1E90FF",fontSize:"40px",}}/>
                <div className='icon-overlay icon-bg4'>4</div>
                </div>
                <p style={{marginTop:"20px",marginBottom:"10px",fontSize:"20px",color:"black"}}>Get Your Solution</p>
                <p>We're Here To Help whenever<p>You Feel But Keeping You</p>Healthy Is Our Better Priority</p>
                
                </div>
              </div>
            </div>
            <div className='doc-listing'>
                <p style={{color:'#FFB1A3',fontSize:"20px",fontWeight:'500',color:"#52B2AD"}}>Our Veterinary</p>
                <p style={{fontSize:"35px",fontWeight:"500",marginTop:"10px"}}>Meet Our Top rated Veterinarians  </p>
                <p style={{fontSize:"17px",fontWeight:"500",color:"lightgray",marginTop:"10px"}}> You will be handled by a team of expert Veterinarians who have more than 10 years exprience in their<p>fields and will get the best solution for problem</p></p>
              </div>


 <div className='veterinary-container'>
      <div className='veterinary-doctor' ref={scrollRef}>
        {Array.from({ length: 8 }).map((_, index) => (
          <div className='veterinary-doctor-1' key={index}>
            <div style={{ backgroundColor: "lightgray" }}>
              <img src={Veterinary1} alt='' style={{ height: "250px", paddingLeft: "30px" }} />
            </div>
            <h3 style={{ paddingTop: "10px", color: "black" }}>Dr. Jasmine Kimberly</h3>
            <h4 style={{ paddingTop: "15px", }}>Veterinary Doctor <span style={{ color: 'orange', paddingLeft: "10px" }}><FontAwesomeIcon icon={faStar} /></span><span style={{ color: "black" }}>4.8 (250)</span></h4>
            <span style={{ color: "red", marginRight: "10px" }}>
              <FontAwesomeIcon icon={faCalendarDays} style={{ paddingTop: "15px", }} />
            </span>
        <span style={{fontWeight:"500"}}>  Mon-Fri</span>  
            <span style={{ color: "red", marginLeft: "20px", }}>
              <FontAwesomeIcon icon={faClock} style={{ paddingRight: "10px", }} />
            </span>
          <span style={{fontWeight:"500"}}>  08:00-17:00</span> 
   <div>           <Link to="/doctor"  style={{ textDecoration: 'none',color:"black" }}>      <button className='veterinary-btn-1'>View Profile</button></Link> </div>
          </div>
        ))}
      </div>
      <div style={{marginLeft:"570px"}}>
      <button onClick={() => scroll('left')} className='scroll-btn'>❮</button>
      <button onClick={() => scroll('right')} className='scroll-btn'>❯</button></div>
    </div>



<div className='more-list-container'>
  <div className='more-list-doc' ref={moreListRef}>
    {Array.from({ length: 7 }).map((_, index) => (
      <div className='more-list-1' key={index}>
        <p style={{ fontSize: "30px", fontWeight: "500" }}>Dr. Diannel<p>Russell</p></p>
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Neurosurgeon Specialist</p>
        <span><FontAwesomeIcon className='more-list-icon' icon={faPhoneVolume} /></span>
        <span><FontAwesomeIcon className='more-list-icon' icon={faCommentDots} style={{marginLeft:"10px"}} /></span>
        <div><Link to="/doctor2"  style={{ textDecoration: 'none',color:"black" }}>  <button className='more-list-btn'>Booking</button> </Link></div>
      </div>
    ))}
  </div>
  
</div>
<div style={{marginTop:"10px",display:"flex",marginLeft:"700px"}}>
  <button onClick={() => scrollMoreList('left')} className='scroll-btn'>❮</button>
  <button onClick={() => scrollMoreList('right')} className='scroll-btn'>❯</button>
  </div>



{/* 
<div className='doc-list-new'>
  <div className='doc-list-new-1'>
  <div style={{paddingLeft:"10px",paddingTop:"20px",}}>
      <p style={{fontSize:"24px",fontWeight:"500"}}>Dr.Raymond</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Cardiology Specialist</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>5 years Experience</p>
      <p style={{backgroundColor:"#EBF6FA",padding:"10px",marginTop:"10px",borderRadius:"20px",fontSize:"17px",fontWeight:"500",paddingTop:"5px"}}>       <FontAwesomeIcon style={{color:"yellow",paddingRight:"10px"}} icon={faStar} />
        5.5 Rating </p>
        <button className='doc-list-new-1btn'>Appointment</button>
    </div>
    <div >

    <img src={YoungDoc} alt='' style={{width:"250px",}}/>
    </div>
    
  </div>


  <div className='doc-list-new-2'>
  <div style={{paddingLeft:"10px",paddingTop:"20px",}}>
      <p style={{fontSize:"24px",fontWeight:"500"}}>Dr.Raymond</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Cardiology Specialist</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>5 years Experience</p>
      <p style={{backgroundColor:"#EBF6FA",padding:"10px",marginTop:"10px",borderRadius:"20px",fontSize:"17px",fontWeight:"500",paddingTop:"5px"}}>       <FontAwesomeIcon style={{color:"yellow",paddingRight:"10px"}} icon={faStar} />
        5.5 Rating </p>
        <button className='doc-list-new-2btn'>Appointment</button>
    </div>
    <div >

    <img src={YoungDoc} alt='' style={{width:"250px",}}/>
    </div>
    
  </div>


  <div className='doc-list-new-3'>
  <div style={{paddingLeft:"10px",paddingTop:"20px",}}>
      <p style={{fontSize:"24px",fontWeight:"500"}}>Dr.Raymond</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Cardiology Specialist</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>5 years Experience</p>
      <p style={{backgroundColor:"#EBF6FA",padding:"10px",marginTop:"10px",borderRadius:"20px",fontSize:"17px",fontWeight:"500",paddingTop:"5px"}}>       <FontAwesomeIcon style={{color:"yellow",paddingRight:"10px"}} icon={faStar} />
        5.5 Rating </p>
        <button className='doc-list-new-3btn'>Appointment</button>
    </div>
    <div >

    <img src={YoungDoc} alt='' style={{width:"250px",}}/>
    </div>
    
  </div>
  <div className='doc-list-new-4'>
  <div style={{paddingLeft:"10px",paddingTop:"20px",}}>
      <p style={{fontSize:"24px",fontWeight:"500"}}>Dr.Raymond</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Cardiology Specialist</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>5 years Experience</p>
      <p style={{backgroundColor:"#EBF6FA",padding:"10px",marginTop:"10px",borderRadius:"20px",fontSize:"17px",fontWeight:"500",paddingTop:"5px"}}>       <FontAwesomeIcon style={{color:"yellow",paddingRight:"10px"}} icon={faStar} />
        5.5 Rating </p>
        <button className='doc-list-new-4btn'>Appointment</button>
    </div>
    <div >

    <img src={YoungDoc} alt='' style={{width:"250px",}}/>
    </div>
    
  </div>
  <div className='doc-list-new-5'>
  <div style={{paddingLeft:"10px",paddingTop:"20px",}}>
      <p style={{fontSize:"24px",fontWeight:"500"}}>Dr.Raymond</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>Cardiology Specialist</p>
      <p style={{fontSize:"16px",fontWeight:"500",paddingTop:"10px"}}>5 years Experience</p>
      <p style={{backgroundColor:"#EBF6FA",padding:"10px",marginTop:"10px",borderRadius:"20px",fontSize:"17px",fontWeight:"500",paddingTop:"5px"}}>       <FontAwesomeIcon style={{color:"yellow",paddingRight:"10px"}} icon={faStar} />
        5.5 Rating </p>
        <button className='doc-list-new-5btn'>Appointment</button>
    </div>
    <div >

    <img src={YoungDoc} alt='' style={{width:"250px",}}/>
    </div>
    
  </div>

</div>   */}




 <div>
      <div className='doc-list-container'>
        <div className='doc-list-new' ref={scrollRef}>
          {[1, 2, 3,].map((_, index) => (
            <div key={index} className={`doc-list-new-${index + 1}`}>
              <div style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>Dr. Raymond</p>
                <p style={{ fontSize: "16px", fontWeight: "500", paddingTop: "10px" }}>Cardiology Specialist</p>
                <p style={{ fontSize: "16px", fontWeight: "500", paddingTop: "10px" }}>5 years Experience</p>
                <p style={{ backgroundColor: "#EBF6FA", padding: "10px", marginTop: "10px", borderRadius: "20px", fontSize: "17px", fontWeight: "500", paddingTop: "5px" }}>
                  <FontAwesomeIcon style={{ color: "yellow", paddingRight: "10px" }} icon={faStar} />
                  5.5 Rating
                </p>
            <Link to='/doctor1' style={{ textDecoration: 'none'}}>      <button className={`doc-list-new-${index + 1}btn`}>Appointment</button></Link>
              </div>
              <div>
                <img src={YoungDoc} alt='' style={{ width: "250px" }} />
              </div>
            </div>
          ))}
        </div>
        
        
      </div>
      <div className='doc-list-container'>
        <div className='doc-list-new' ref={scrollRef}>
          {[1, 2, 3,].map((_, index) => (
            <div key={index} className={`doc-list-new-${index + 1}`}>
              <div style={{ paddingLeft: "10px", paddingTop: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "500" }}>Dr. Raymond</p>
                <p style={{ fontSize: "16px", fontWeight: "500", paddingTop: "10px" }}>Cardiology Specialist</p>
                <p style={{ fontSize: "16px", fontWeight: "500", paddingTop: "10px" }}>5 years Experience</p>
                <p style={{ backgroundColor: "#EBF6FA", padding: "10px", marginTop: "10px", borderRadius: "20px", fontSize: "17px", fontWeight: "500", paddingTop: "5px" }}>
                  <FontAwesomeIcon style={{ color: "yellow", paddingRight: "10px" }} icon={faStar} />
                  5.5 Rating
                </p>
            <Link to='/doctor1' style={{ textDecoration: 'none'}}>      <button className={`doc-list-new-${index + 1}btn`}>Appointment</button></Link>
              </div>
              <div>
                <img src={YoungDoc} alt='' style={{ width: "250px" }} />
              </div>
            </div>
          ))}
        </div>
        
        
      </div>
{/* 
      <div style={{marginLeft:"680px"}}>
        <button onClick={() => scroll('left')} className='scroll-btn'>❮</button>
       
        <button onClick={() => scroll('right')} className='scroll-btn'>❯</button></div> */}
    </div> 

 











    

            {/* <div style={{marginTop:"50px"}}>
            <div className='Quick-1'>
                <p style={{color:'#FFB1A3',fontSize:"20px",fontWeight:'500'}}>Our Veterinary</p>
                <p style={{fontSize:"35px",fontWeight:"500",marginTop:"10px"}}>Meet Our Top rated Veterinarians  </p>
                <p style={{fontSize:"17px",fontWeight:"500",color:"lightgray",marginTop:"10px"}}> You will be handled by a team of expert Veterinarians who have more than 10 years exprience in their<p>fields and will get the best solution for problem</p></p>
              </div>
              </div>
              <div className="doctor-container" style={{ position: 'relative', width: '69rem', marginLeft: '200px' }}>
  
              <button className="scroll-button left" style={{marginLeft:"480px"}} onClick={scrollLeft}>&lt;</button>
  
              <div className='doctor-name-list'>
                
              <Link to="/doctor"  style={{ textDecoration: 'none',color:"black" }}>   <div className='doctor-name1'> <img src={doctor2} alt='' style={{height:"250px",backgroundColor:"#ffb1a3b3",borderTopLeftRadius:"50%"}}/>
               <h4 style={{paddingLeft:"15px",paddingTop:"10px",color:"black"}}>Dr. Jasmine Kimberly</h4> <h4 style={{paddingLeft:"15px",paddingTop:"15px",color:"#ff8da1",}}>Veterinary Doctor <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4>  <span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"15px",paddingTop:"15px",}} />
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px", }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px",}} />
                </span>
                08:00-17:00  </div></Link>
                <Link to="/doctor1"  style={{ textDecoration: 'none',color:"black" }}> <div className='doctor-name2'><img src={doctor1} alt='' style={{height:"250px",backgroundColor:"#67d5998e",borderTopRightRadius:"50%",borderTopLeftRadius:"50%"}}/>
              <h4 style={{paddingLeft:"20px",paddingTop:"10px"}}>Dr. Michael Grossett</h4><h4 style={{paddingLeft:"20px",paddingTop:"15px",color:'#67d5998e'}}> Neurosurgeon <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4><span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"15px"}}/>
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}} />
                </span>
                08:00-17:00</div></Link>
                <Link to="/doctor1"  style={{ textDecoration: 'none',color:"black" }}> <div className='doctor-name2'><img src={doctor1} alt='' style={{height:"250px",backgroundColor:"#67d5998e",borderTopRightRadius:"50%",borderTopLeftRadius:"50%"}}/>
              <h4 style={{paddingLeft:"20px",paddingTop:"10px"}}>Dr. Michael Grossett</h4><h4 style={{paddingLeft:"20px",paddingTop:"15px",color:'#67d5998e'}}> Neurosurgeon <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4><span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"15px"}}/>
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}} />
                </span>
                08:00-17:00</div></Link>
            
                <Link to="/doctor1"  style={{ textDecoration: 'none',color:"black" }}>   <div className='doctor-name3'><img src={doctor1} alt='' style={{height:"250px",backgroundColor:"#f8c669b1",borderTopRightRadius:"50%",borderTopLeftRadius:"50%"}}/>
              <h4 style={{paddingLeft:"20px",paddingTop:"10px"}}>Dr. Michael Grossett</h4><h4 style={{paddingLeft:"20px",paddingTop:"15px",color:"#f8c669b1"}}> Neurosurgeon <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4><span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"15px"}}/>
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}}/>
                </span>
                08:00-17:00</div></Link>
                <Link to="/doctor1"  style={{ textDecoration: 'none',color:"black" }}>   <div className='doctor-name3'><img src={doctor1} alt='' style={{height:"250px",backgroundColor:"#f8c669b1",borderTopRightRadius:"50%",borderTopLeftRadius:"50%"}}/>
              <h4 style={{paddingLeft:"20px",paddingTop:"10px"}}>Dr. Michael Grossett</h4><h4 style={{paddingLeft:"20px",paddingTop:"15px",color:"#f8c669b1"}}> Neurosurgeon <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4><span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"20px",paddingTop:"15px"}}/>
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock} style={{paddingRight:"10px"}}/>
                </span>
                08:00-17:00</div></Link>
          
                <Link to="/doctor"  style={{ textDecoration: 'none',color:"black" }}>      <div className='doctor-name4'><img src={doctor2} alt='' style={{height:"250px",backgroundColor:"#6fb0f0b6",borderTopRightRadius:"50%"}}/>
              <h4 style={{paddingLeft:"15px",paddingTop:"10px"}}>Dr. Jasmine Kimberly</h4><h4 style={{paddingLeft:"15px",paddingTop:"15px",color:"#6fb0f0b6"}}>Veterinary Doctor <span style={{color:'orange',paddingLeft:"10px"}}><FontAwesomeIcon icon={faStar} /></span><span style={{color:"black"}}>4.8 (250)</span> </h4>  <span style={{ color: "red", marginRight: "10px" }}>
                  <FontAwesomeIcon icon={faCalendarDays} style={{paddingLeft:"15px",paddingTop:"15px"}}/>
                </span>
                Mon-Fri
                <span style={{ color: "red", marginLeft: "20px" }}>
                  <FontAwesomeIcon icon={faClock}  style={{paddingRight:"10px"}}/>
                </span>
                08:00-17:00 </div></Link>

              </div>
              <button className="scroll-button right" style={{marginRight:"520px"}}  onClick={scrollRight}>&gt;</button>
        
              </div>
               */}








         <div>

            {/* <div className='dr-card-wrapper'>
            {
            doctors.map((_,index)=> <DrCard doctor={_} key={index}/>)
         }
            </div>
         */}
         </div>
         

   <p className='client-head'>Client's <span style={{color:"#52B2AD"}}> Review</span></p>
<div className='client-review-container' style={{ position: 'relative', width: '72rem',}}>
      <div className='client-review' id='review-container'>
        {Array.from({ length: 9 }).map((_, index) => (
          <div className='client1' key={index}>
            <div className='client-color'>
              {/* <img src={client} alt='' style={{ borderRadius: "100px", height: "100px", width: "100px", marginTop: "20px" }} /> */}
              <p style={{ paddingTop: "10px", fontSize: "20px", fontWeight: "600", color: 'white' ,paddingLeft:"100px"}}>Ana Rhodes</p>
              <div className='fonticon'>
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStarHalfStroke} />
              </div>
            </div>
            <p className='review-text'>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
          </div>
        ))}
      </div>
     </div>
     <div className='review-scroll-btn'>
     <button className='scroll-button1' onClick={scrollLefts}> </button>
     <button className='scroll-button2' onClick={scrollRights}> </button>
     {/* right &gt; left &lt; */}
     </div>
         
    


     <p className='client-head'>Client's <span style={{color:"#52B2AD"}}> Review</span></p>

<div className="review-container">
            <div className="review-box-client" ref={scrollRef}>
                {[...Array(5)].map((_, index) => (
                    <div className='review-box1' key={index}>
                        <img src={client1} alt='' style={{ width: "350px", borderRadius: "10px", borderBottomLeftRadius: "100px" }} />
                        <p style={{ fontSize: "17px", fontWeight: "500", color: "gray", paddingTop: "10px" }}>
                            Lorem ipsum dolor sit Amet consectetur
                            <p>adipiscing elit sed to eiusmod Tempor</p>
                            <p>Incididunt Ut labore. Lorem ipsum dolor sit</p>
                            <p>Amet consectetuer adipiscing elit sed do</p>
                            <p>Eiusmod Tempor incididunt Ut labore.</p>
                            <p>Lorem ipsum Dolor Sit Amet</p>
                        </p>
                        <p style={{ fontSize: "19px", fontWeight: "500", paddingTop: "20px",paddingBottom:"10px" }}>
                            Ana Rhodes
                            <span style={{ paddingLeft: "100px", color: "#FFFF00" }}>
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStar} />
                                <FontAwesomeIcon icon={faStarHalfStroke} />
                            </span>
                        </p>
                    </div>
                ))}
            </div>
        
        </div>

        <div className='client-review-btn-1'>
            <button className="scroll-button1" onClick={handleScrollLeft}></button>
          
            <button className="scroll-button2" onClick={handleScrollRight}></button>
            </div> 




            <footer className="footer12">
            <div className="footer-row">
                <h3 style={{color:'white'}}>Quick Links</h3>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Home</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Services</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>About</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Doctors</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Review</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Book</p>
            </div>
            <div className="footer-row">
                <h3 style={{color:'white'}}>Our Services</h3>
                <p> <FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/> Ambulance Service</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Veterinary Doctor</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Neurosurgeon</p>
                <p><FontAwesomeIcon icon={faChevronRight} style={{paddingRight:"10px"}}/>Cardiology</p>
            </div>
            <div className="footer-row">
                <h3 style={{color:'white'}}>Contact Info</h3>
                <p><FontAwesomeIcon icon={faPhone} /> Phone: +123 456 7890</p>
                <p><FontAwesomeIcon icon={faPhone} /> Phone: +123 111 9990</p>
                <p> <FontAwesomeIcon icon={faEnvelope} /> Email: contact@example.com</p>
                <p>  <FontAwesomeIcon icon={faEnvelope} /> Email: contact@example.com</p>
                <p><FontAwesomeIcon icon={faLocation} />  Mumbai,India-400104</p>
                
            </div>
            <div className="footer-row">
                <h3 style={{color:'white'}}>Follow Us</h3>
                <p><FontAwesomeIcon icon={faFacebook}  /> Facebook</p>
                <p><FontAwesomeIcon icon={faTwitter} /> Twitter</p>
                <p><FontAwesomeIcon icon={faInstagram} /> Instagram</p>
                <p><FontAwesomeIcon icon={faLinkedin} /> Linkedin</p>
                <p><FontAwesomeIcon icon={faPinterest} /> Pinterest</p>
            </div>
        </footer>

        
        </main>
      </div>
    </div>
  );
}

export default Home;
