import { useEffect, useState } from 'react';
import { Outlet, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faLaptopMedical, faDog, faArrowRightFromBracket, faBars } from '@fortawesome/free-solid-svg-icons';
import '../styles/layout.css';
import Header from "../navigation";

const Layout = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        if (!userData) navigate("/login");
    }, [navigate]);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className='main-view-container'>
            <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                <div className='sidebar-logo'>
                    <FontAwesomeIcon 
                        icon={faBars} 
                        className='faBars' 
                        style={{ height: "20px" }} 
                        onClick={toggleSidebar}
                    />
                    {isSidebarOpen && <div className='logo-text'>metavet</div>}
                </div>
                <nav className='sidebar-nav'>
                    <NavLink 
                        to="/dashboard"  
                        className={({ isActive }) => (isActive ? 'active' : '')} 
                    >
                        <FontAwesomeIcon icon={faCalendarCheck} className='fa-icon' />
                        {isSidebarOpen && 'Dashboard'}
                    </NavLink>

                    <NavLink 
                        to="/appoint" 
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <FontAwesomeIcon icon={faCalendarCheck} className='fa-icon' />
                        {isSidebarOpen && 'Appointments'}
                    </NavLink>

                    <NavLink 
                        to="/health" 
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <FontAwesomeIcon icon={faLaptopMedical} className='fa-icon' />
                        {isSidebarOpen && 'Health Record'}
                    </NavLink>

                    <NavLink 
                        to="/mypet" 
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        <FontAwesomeIcon icon={faDog} className='fa-icon' />
                        {isSidebarOpen && 'My Pet'}
                    </NavLink>

                    <a href='#logout' >
                        <FontAwesomeIcon icon={faArrowRightFromBracket} className='fa-icon' />
                        {isSidebarOpen && 'Logout'}
                    </a>
                </nav>
            </div>
            <div className='main-content'>
                <Header />
                <Outlet />
            </div>
        </div>
    );
}

export default Layout;






























































